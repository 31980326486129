import React from "react"
import dayjs from "dayjs"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

interface Seo {
  title: string
  description?: string
  titleTemplate?: string
  article: boolean
  createdAt?: string
  updatedAt?: string
  url: string
  image?: string
}

export const Seo = ({
  title,
  description,
  titleTemplate,
  article = false,
  createdAt,
  updatedAt,
  url,
  image,
}: Seo) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            title
            titleTemplate
            description
            image
            social {
              twitter
              linkedIn
              facebook
              instagram
            }
            siteUrl
          }
        }
      }
    `
  )

  return (
    <Helmet
      title={`${title ? title : site?.siteMetadata?.title} ${
        titleTemplate ? titleTemplate : site?.siteMetadata?.titleTemplate
      }`}
    >
      <meta
        property="og:title"
        content={`${title ? title : site?.siteMetadata?.title} ${
          titleTemplate ? titleTemplate : site?.siteMetadata?.titleTemplate
        }`}
      />
      <meta property="og:site_name" content={site?.siteMetadata?.name} />
      {/* Urls */}
      <link rel="canonical" href={url} />
      <meta property="og:url" content={url} />
      {/* Description */}
      <meta
        name="description"
        content={description ? description : site?.siteMetadata?.description}
      />
      <meta
        property="og:description"
        content={description ? description : site?.siteMetadata?.description}
      />
      {/* Author */}
      {article && <meta property="og:type" content="article" />}
      {article && createdAt && (
        <meta
          property="article:published_time"
          content={dayjs(createdAt).toISOString()}
        />
      )}
      {article && updatedAt && (
        <meta
          property="article:modified_time"
          content={dayjs(updatedAt).toISOString()}
        />
      )}
      {article && (
        <meta
          property="article:publisher"
          content={site?.siteMetadata?.social?.facebook}
        />
      )}
      {article && (
        <meta
          property="article:author"
          content={site?.siteMetadata?.social?.facebook}
        />
      )}
      {/* Twitter */}
      <meta
        name="twitter:title"
        content={title ? title : site?.siteMetadata?.title}
      />
      <meta name="twitter:creator" content="@gotthetest" />
      <meta name="twitter:site" content={site?.siteMetadata?.siteUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:description"
        content={description ? description : site?.siteMetadata?.description}
      />
      {/* Images */}
      <meta
        name="twitter:image"
        content={image ? image : site?.siteMetadata?.image}
      />
      <meta
        property="og:image"
        content={image ? image : site?.siteMetadata?.image}
      />
      <meta property="og:image:width" content="1024" />
      <meta property="og:image:height" content="768" />
    </Helmet>
  )
}
