import React from "react"

interface Props {
  children: React.ReactNode
  background?: boolean
  type?: "warning" | "error" | "info" | "success"
  icon?: boolean
  variant?: "margin-top"
}

export const Tag = ({
  background = true,
  children,
  icon = true,
  type = "success",
  variant,
}: Props) => {
  if (icon) {
    return (
      <span
        className={`tag tag-${type} tag-icon${
          background ? " tag-background" : ""
        }${variant ? ` tag-${variant}` : ""}`}
      >
        <span
          className={`icon-${
            type === "success" ? "tick-circle-fill" : "alert-circle-outline"
          } tag-icon-icon`}
        />
        <span className="tag-icon-text">{children}</span>
      </span>
    )
  } else {
    return (
      <span
        className={`tag tag-${type}${background ? " tag-background" : ""}${
          variant ? ` tag-${variant}` : ""
        }`}
      >
        {children}
      </span>
    )
  }
}
