import React from "react"
import SVG from "react-inlinesvg"

interface Props {
  icon: string
}

export const UseCaseSymbol = ({ icon }: Props) => (
  <div className="use-case-symbol">
    <SVG className="use-case-symbol-icon" src={icon} />
  </div>
)
