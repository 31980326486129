import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const options: ContentfulRichText = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => (
      <GatsbyImage
        image={node.data.target.gatsbyImageData}
        alt={node.data.alt}
      />
    ),
    [BLOCKS.UL_LIST]: (node: any) => (
      <ul className="bullets-icon bullets-icon-rich-text">
        {node.content.map((bullet: any) => (
          <li
            className="bullets-icon-bullet"
            key={bullet.content[0].content[0].value}
          >
            <span className="icon-tick-circle-fill bullets-icon-bullet-icon" />
            <span className="bullets-icon-bullet-text">
              {bullet.content[0].content[0].value}
            </span>
          </li>
        ))}
      </ul>
    ),
  },
}

interface ContentfulRichText {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]?: {}
    [BLOCKS.UL_LIST]?: {}
    [BLOCKS.HEADING_1]?: string
    [BLOCKS.HEADING_2]?: string
    [BLOCKS.HEADING_3]?: string
    [BLOCKS.HEADING_4]?: string
    [BLOCKS.HEADING_5]?: string
    [BLOCKS.HEADING_6]?: string
  }
}

interface Props {
  content: any
  variant?: "header-image" | "section" | "accordion-body"
}

export const RichTextTickBullets = ({ content, variant }: Props) => (
  <div className={`rich-text${variant ? ` rich-text-${variant}` : ""}`}>
    {content && renderRichText(content, options)}
  </div>
)
