import React from "react"

import { BulletsIcon } from "../../.."
import { Bullet } from "../../../../types"

interface Props {
  heading: string
  tickBullets: Array<string>
}

export const SectionCaseStudyWhatWeDid = ({ heading, tickBullets }: Props) => (
  <section className="case-study-section">
    <div className="background-grey-lightest section-case-study-what-we-did">
      <h2 className="heading-medium section-case-study-what-we-did-heading">
        {heading}
      </h2>
      <BulletsIcon
        gap="large"
        bullets={tickBullets.map((tickBullet) => ({
          icon: "tick-circle-fill",
          text: tickBullet,
        }))}
      />
    </div>
  </section>
)
