import React from "react"
import { Link } from "gatsby"

interface Button {
  style?: "delete" | "disabled" | "primary" | "secondary" | "tertiary" | "green"
  loading?: boolean
  children: React.ReactNode
  to?: string
  onClick?: () => void
  variant?:
    | "mobile-menu"
    | "nav-main"
    | "full-width"
    | "tab"
    | "form-join-mailing-list-blog"
  url?: string
  disabled?: boolean
  type?: "button" | "submit"
}

export const Button = ({
  children,
  loading = false,
  onClick,
  to,
  style = "primary",
  variant,
  url,
  disabled = false,
  type = "button",
}: Button) => {
  if (to) {
    return (
      <Link
        to={to}
        className={`button button-${style}${
          variant ? ` button-${variant}` : ""
        }`}
      >
        {children}
      </Link>
    )
  } else if (onClick) {
    return (
      <button
        className={`button button-${style}${loading ? " button-loading" : ""}${
          variant ? ` button-${variant}` : ""
        }`}
        type={type}
        disabled={disabled}
        onClick={onClick}
      >
        <span className="button-text">{children}</span>
      </button>
    )
  } else if (url) {
    return (
      <a
        href={url}
        className={`button button-${style}${
          variant ? ` button-${variant}` : ""
        }`}
      >
        {children}
      </a>
    )
  } else {
    return (
      <button
        className={`button button-${style}${loading ? " button-loading" : ""}${
          variant ? ` button-${variant}` : ""
        }`}
        type={type}
      >
        <span className="button-text">{children}</span>
      </button>
    )
  }
}
