import React from "react"

import { RichTextTickBullets } from "../.."
import { AccordionType } from "../../../types"

export const Accordion = ({ body, heading }: AccordionType) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className={`accordion${isOpen ? " accordion-open" : ""}`}>
      <button
        className="accordion-header"
        onClick={() => setIsOpen((cur) => !cur)}
      >
        <h3 className="accordion-header-heading">{heading}</h3>
        <span className="icon-chevron-down accordion-header-icon" />
      </button>
      <div className="accordion-body">
        <RichTextTickBullets variant="accordion-body" content={body} />
      </div>
    </div>
  )
}
