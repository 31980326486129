import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import {
  RichTextTickBullets,
  ButtonList,
  ButtonWithIcon,
  Button,
  Video,
} from "../.."
import { TextWithImage } from "../../../types"
import { useCaseSlug } from "../../../utils"

import Blob from "../../../images/blob.inline.svg"

interface UseCases extends Array<UseCase> {}

interface UseCase {
  node: {
    contentful_id: string
    name: string
    slug: string
  }
}

export const SectionTextWithImage = ({
  backgroundColour,
  children,
  content,
  contentful_id,
  file,
  heading,
  image,
  imageAlt,
  imageBlobBackground,
  scheduleDemoButton,
  showImageFirst,
  showSignUpButton,
  variant,
  youTubeVideo,
}: TextWithImage) => {
  const data = useStaticQuery(
    graphql`
      query useCaseList {
        allContentfulPageUseCase(sort: { order: DESC, fields: name }) {
          edges {
            node {
              contentful_id
              name
              slug
            }
          }
        }
      }
    `
  )
  const useCases: UseCases = data?.allContentfulPageUseCase?.edges
  const noImage = !image && !imageAlt && !file && !youTubeVideo
  return (
    <section className={`background-${backgroundColour}`}>
      <section
        className={`wrapper-width wrapper-height wrapper-height-large section-text-with-image${
          showImageFirst
            ? " section-text-with-image-image-first"
            : " section-text-with-image-text-first"
        }${variant ? ` section-text-with-image-${variant}` : ""}${
          noImage ? " section-text-with-no-image" : ""
        }`}
      >
        <div
          className={`section-text-with-image-content${
            imageBlobBackground ? " section-text-with-image-content-blob" : ""
          }`}
        >
          <div className="section-text-with-image-content-wrapper">
            <h2 className="heading-large section-text-with-image-content-heading">
              {heading}
            </h2>
            <RichTextTickBullets content={content} />
            {children}
            {contentful_id === "5l8kdpbOa8FJ7MwAT2PP9Y" && (
              <ButtonList variant="vertical">
                {useCases.map(({ node }) => (
                  <ButtonWithIcon
                    key={node.contentful_id}
                    style="secondary"
                    to={useCaseSlug(node.slug)}
                  >
                    {node.name}
                  </ButtonWithIcon>
                ))}
              </ButtonList>
            )}
            {showSignUpButton || scheduleDemoButton ? (
              <ButtonList>
                {showSignUpButton && (
                  <Button
                    style="primary"
                    url={`${process.env.GATSBY_PATIENT_PORTAL}/sign-up`}
                  >
                    Sign up free
                  </Button>
                )}
                {scheduleDemoButton && (
                  <Button
                    style="secondary"
                    url="https://calendly.com/gotthetest/intro-call-demo"
                  >
                    Schedule demo
                  </Button>
                )}
              </ButtonList>
            ) : (
              ""
            )}
          </div>
        </div>
        {!noImage && (
          <div
            className={`section-text-with-image-image${
              imageBlobBackground ? " section-text-with-image-image-blob" : ""
            }`}
          >
            {imageBlobBackground && !youTubeVideo && (
              <div className="section-text-with-image-blob">
                <Blob />
              </div>
            )}
            {image && imageAlt ? (
              <GatsbyImage
                className="section-text-with-image-images"
                image={image}
                alt={imageAlt}
              />
            ) : file && file?.split(".mp4").length > 1 ? (
              <Video
                videoSrcURL={file}
                videoTitle={imageAlt}
                variant="section-text-with-image"
              />
            ) : youTubeVideo ? (
              <div
                className="section-text-with-image-youtube"
                dangerouslySetInnerHTML={{ __html: youTubeVideo }}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </section>
    </section>
  )
}
