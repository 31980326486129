import React from "react"

interface Props {
  children: React.ReactNode
  variant?: "vertical" | "centered" | "vertical-merged"
}

export const ButtonList = ({ children, variant }: Props) => (
  <div className={`button-list${variant ? ` button-list-${variant}` : ""}`}>
    {children}
  </div>
)
