import React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

interface Props {
  image: IGatsbyImageData
  imageAlt: string
}

export const SectionCaseStudyImageOnly = ({ image, imageAlt }: Props) => (
  <section className="case-study-section">
    <GatsbyImage image={image} alt={imageAlt} />
  </section>
)
