import React from "react"

import { Accordion } from "./Accordion"
import { AccordionsType } from "../../types"

export const Accordions = ({ accordions }: AccordionsType) => (
  <ul className="accordions">
    {accordions.map((accordion) => (
      <Accordion
        key={accordion.heading}
        heading={accordion.heading}
        body={accordion.body}
      />
    ))}
  </ul>
)
