import React from "react"

interface Props {
  children: React.ReactNode
  heading: string
  alignment: "left" | "center"
}

export const HeaderTextOnly = ({ heading, alignment, children }: Props) => (
  <header
    className={`wrapper-width header-text-only header-text-only-${alignment}`}
  >
    <h1 className="heading-extra-large header-text-only-heading">{heading}</h1>
    {children}
  </header>
)
