import React from "react"
import { RichTextTickBullets } from "../../.."

interface Props {
  content: string
}

export const SectionCaseStudyTextOnly = ({ content }: Props) => (
  <section className="case-study-section">
    <RichTextTickBullets content={content} />
  </section>
)
