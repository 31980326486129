import React from "react"
import { Link } from "gatsby"

interface Props {
  to: string
  children: React.ReactNode
  variant: "blog-topics" | "mobile-menu"
  tabIndex?: -1 | 0
  external?: boolean
}

export const NavLink = ({
  to,
  variant,
  children,
  tabIndex,
  external = false,
}: Props) => (
  <li className={`nav-link-${variant}`}>
    {external ? (
      <a className="nav-link" target="_blank" rel="noopener" href={to}>
        <span className="nav-link-text">{children}</span>
        <span className="icon-external-link nav-link-icon" />
      </a>
    ) : (
      <Link className="nav-link" to={to} tabIndex={tabIndex}>
        <span className="nav-link-text">{children}</span>
        <span className="icon-chevron-right nav-link-icon" />
      </Link>
    )}
  </li>
)
