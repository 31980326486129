import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useOnClickOutside } from "usehooks-ts"

import { IconButton, Button } from "../.."
import { UseCasesDropdown } from "./UseCasesDropdown"
import { BlogTopicsDropdown } from "./BlogTopicsDropdown"
import { UseCaseLinks, BlogTopicLinks } from "../../../types"
import { NavLink } from "./NavLink"

import GTTLogo from "../../../images/gotthetest-logo-dark.inline.svg"

export const Nav = () => {
  const [mobileNavVisible, setMobileNavVisibility] = React.useState(false)
  // Use Cases
  const [mobileUseCasesVisible, setMobileUseCasesVisible] =
    React.useState(false)
  const [useCasesVisible, setUseCasesVisible] = React.useState(false)
  const useCasesDropdownDesktopRef = React.useRef(null)

  useOnClickOutside(useCasesDropdownDesktopRef, () => setUseCasesVisible(false))
  // Blog Topics
  const [mobileBlogTopicsVisible, setMobileBlogTopicsVisible] =
    React.useState(false)
  const [blogTopicsVisible, setBlogTopicsVisible] = React.useState(false)
  const blogTopicsDropdownDesktopRef = React.useRef(null)

  useOnClickOutside(blogTopicsDropdownDesktopRef, () =>
    setBlogTopicsVisible(false)
  )

  const data = useStaticQuery(
    graphql`
      query navContent {
        allContentfulPageUseCase(sort: { order: DESC, fields: name }) {
          edges {
            node {
              contentful_id
              name
              slug
              summary
              icon {
                file {
                  url
                }
              }
            }
          }
        }
        allContentfulBlogArticleTopic(sort: { order: ASC, fields: name }) {
          edges {
            node {
              contentful_id
              name
              slug
            }
          }
        }
      }
    `
  )

  const useCases: UseCaseLinks = data?.allContentfulPageUseCase?.edges
  const blogTopics: BlogTopicLinks = data?.allContentfulBlogArticleTopic?.edges

  return (
    <div className="nav">
      <section className="nav-main">
        <section className="nav-header nav-main-mobile">
          <Link to="/" className="nav-header-logo">
            <GTTLogo />
          </Link>
          <IconButton
            icon="menu"
            onClick={() => setMobileNavVisibility(true)}
          />
        </section>
        <nav className="nav-desktop">
          <Link to="/" className="nav-desktop-logo">
            <GTTLogo />
          </Link>
          <ul className="nav-desktop-links">
            <li ref={useCasesDropdownDesktopRef}>
              <button
                className="nav-desktop-dropdown"
                onClick={() => setUseCasesVisible((cur) => !cur)}
              >
                <span className="nav-main-dropdown-text">Use cases</span>
                <span className="icon-chevron-down-thick nav-main-dropdown-icon" />
              </button>
              <UseCasesDropdown
                variant="main-menu"
                isOpen={useCasesVisible}
                useCases={useCases}
              />
            </li>
            <li ref={blogTopicsDropdownDesktopRef}>
              <button
                className="nav-desktop-dropdown"
                onClick={() => setBlogTopicsVisible((cur) => !cur)}
              >
                <span className="nav-main-dropdown-text">Blog</span>
                <span className="icon-chevron-down-thick nav-main-dropdown-icon" />
              </button>
              <BlogTopicsDropdown
                variant="main-menu"
                isOpen={blogTopicsVisible}
                blogTopics={blogTopics}
              />
            </li>
            <li>
              <Link className="nav-desktop-link" to="/pricing/">
                Pricing
              </Link>
            </li>
            <li>
              <Link className="nav-desktop-link" to="/case-studies/">
                Case studies
              </Link>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener"
                className="nav-desktop-link"
                href="https://store.gotthetest.com/"
              >
                Store
              </a>
            </li>
          </ul>
          <div className="nav-desktop-buttons">
            <Button
              variant="nav-main"
              style="secondary"
              url={process.env.GATSBY_PATIENT_PORTAL}
            >
              Login
            </Button>
            <Button
              variant="nav-main"
              style="primary"
              url={`${process.env.GATSBY_PATIENT_PORTAL}/sign-up`}
            >
              Sign up free
            </Button>
          </div>
        </nav>
      </section>
      <div
        className={`nav-mobile${mobileNavVisible ? " nav-mobile-active" : ""}`}
      >
        <header className="nav-header">
          <Link to="/" className="nav-header-logo">
            <GTTLogo />
          </Link>
          <IconButton
            icon="cross"
            onClick={() => setMobileNavVisibility(false)}
          />
        </header>
        <section className="nav-mobile-body">
          <Button
            style="primary"
            variant="mobile-menu"
            url={`${process.env.GATSBY_PATIENT_PORTAL}/sign-up`}
          >
            Sign up free
          </Button>
          <Button
            style="secondary"
            variant="mobile-menu"
            url={process.env.GATSBY_PATIENT_PORTAL}
          >
            Login
          </Button>
          <ul className="nav-mobile-body-links">
            <li className="nav-mobile-body-links-item nav-mobile-body-link-dropdown">
              <button
                className="nav-mobile-body-link-dropdown-button"
                onClick={() => setMobileUseCasesVisible((cur) => !cur)}
              >
                <span className="nav-mobile-body-link-dropdown-button-text">
                  Use cases
                </span>
                <span
                  className={`icon-chevron-down nav-mobile-body-link-dropdown-button-icon${
                    mobileUseCasesVisible
                      ? " nav-mobile-body-link-dropdown-button-icon-active"
                      : ""
                  }`}
                />
              </button>
              <UseCasesDropdown
                variant="mobile-menu"
                isOpen={mobileUseCasesVisible}
                useCases={useCases}
              />
            </li>
            <li className="nav-mobile-body-links-item nav-mobile-body-link-dropdown">
              <button
                className="nav-mobile-body-link-dropdown-button"
                onClick={() => setMobileBlogTopicsVisible((cur) => !cur)}
              >
                <span className="nav-mobile-body-link-dropdown-button-text">
                  Blog
                </span>
                <span
                  className={`icon-chevron-down nav-mobile-body-link-dropdown-button-icon${
                    mobileBlogTopicsVisible
                      ? " nav-mobile-body-link-dropdown-button-icon-active"
                      : ""
                  }`}
                />
              </button>
              <BlogTopicsDropdown
                variant="mobile-menu"
                isOpen={mobileBlogTopicsVisible}
                blogTopics={blogTopics}
              />
            </li>
            <NavLink variant="mobile-menu" to="/pricing/">
              Pricing
            </NavLink>
            <NavLink variant="mobile-menu" to="/case-studies/">
              Case studies
            </NavLink>
            <NavLink
              variant="mobile-menu"
              to="https://store.gotthetest.com/"
              external
            >
              Store
            </NavLink>
          </ul>
        </section>
      </div>
      <div
        onClick={() => setMobileNavVisibility(false)}
        className={`nav-mobile-overlay${
          mobileNavVisible ? " nav-mobile-overlay-active" : ""
        }`}
      />
      <div className="nav-shadow" />
    </div>
  )
}
