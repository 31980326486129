import React from "react"

import { BulletsIconBullet } from "./BulletsIconBullet"
import { Bullet } from "../../types"

interface Props {
  gap?: "large"
  bullets: Array<Bullet>
}

export const BulletsIcon = ({ bullets, gap }: Props) => (
  <ul
    className={`bullets-icon${
      gap === "large" ? " bullets-icon-gap-large" : ""
    }`}
  >
    {bullets.map((bullet: Bullet) => (
      <li key={bullet.text}>
        <BulletsIconBullet icon={bullet.icon}>{bullet.text}</BulletsIconBullet>
      </li>
    ))}
  </ul>
)
