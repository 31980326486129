import React from "react"
import { Link } from "@reach/router"

interface Props {
  icon?:
    | "arrow-bottom"
    | "arrow-left"
    | "arrow-right"
    | "arrow-top"
    | "chevron-down"
    | "chevron-up"
    | "cross"
    | "download"
    | "email"
    | "external-link"
    | "filter"
    | "lock"
    | "plus"
    | "upload"
  iconPosition?: "left" | "right"
  style?: "primary" | "secondary" | "tertiary" | "delete"
  children: React.ReactNode
  onClick?: any
  loading?: boolean
  href?: string
  to?: string
}

export const ButtonWithIcon = ({
  icon = "arrow-right",
  iconPosition = "right",
  style = "primary",
  children,
  onClick,
  loading = false,
  href,
  to,
  ...props
}: Props) => {
  if (onClick) {
    return (
      <button
        className={`button button-large button-${style} button-with-icon button-with-icon-${iconPosition}${
          loading ? " button-loading" : ""
        }`}
        onClick={onClick}
        {...props}
      >
        <span className={`button-with-icon-icon icon-${icon}`}></span>
        <span className="button-with-icon-text">{children}</span>
      </button>
    )
  }
  if (href) {
    return (
      <a
        className={`button button-large button-${style} button-with-icon button-with-icon-${iconPosition}`}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        <span className={`button-with-icon-icon icon-${icon}`}></span>
        <span className="button-with-icon-text">{children}</span>
      </a>
    )
  }
  if (to) {
    return (
      <Link
        className={`button button-large button-${style} button-with-icon button-with-icon-${iconPosition}`}
        to={to}
      >
        <span className={`button-with-icon-icon icon-${icon}`}></span>
        <span className="button-with-icon-text">{children}</span>
      </Link>
    )
  } else {
    return (
      <button
        className={`button button-large button-${style} button-with-icon button-with-icon-${iconPosition}${
          loading ? " button-loading" : ""
        }`}
        {...props}
      >
        <span className={`button-with-icon-icon icon-${icon}`}></span>
        <span className="button-with-icon-text">{children}</span>
      </button>
    )
  }
}
