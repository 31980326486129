import React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

interface Props {
  heading: string
  image: IGatsbyImageData
  imageAlt: string
  logo: IGatsbyImageData
  logoAlt: string
  summary: string
}

export const HeaderCaseStudy = ({
  heading,
  image,
  imageAlt,
  logo,
  logoAlt,
  summary,
}: Props) => {
  return (
    <header className="header-case-study-wrapper">
      <div className="background-grey-darkest header-case-study-background" />
      {/* Position absolute so it can appear above background */}
      <section className="wrapper-width header-case-study">
        <div className="header-case-study-content">
          <span className="sub-heading sub-heading-large">Case study</span>
          <h1 className="heading-extra-large header-case-study-content-heading">
            {heading}
          </h1>
          <p className="header-case-study-content-summary">{summary}</p>
          <GatsbyImage
            className="header-case-study-content-logo"
            image={logo}
            alt={logoAlt}
          />
        </div>
        <div className="header-case-study-image">
          <GatsbyImage
            className="header-case-study-image-image"
            image={image}
            alt={imageAlt}
          />
        </div>
      </section>
      {/* Hidden content (duplicate of above) so correct layout height can be calculated */}
      <section className="wrapper-width header-case-study header-case-study-shadow">
        <div className="header-case-study-content">
          <span className="sub-heading sub-heading-large">Case study</span>
          <h1 className="heading-extra-large header-case-study-content-heading">
            {heading}
          </h1>
          <p className="header-case-study-content-summary">{summary}</p>
          <GatsbyImage
            className="header-case-study-content-logo"
            image={logo}
            alt={logoAlt}
          />
        </div>
        <div className="header-case-study-image">
          <GatsbyImage
            className="header-case-study-image-image"
            image={image}
            alt={imageAlt}
          />
        </div>
      </section>
    </header>
  )
}
