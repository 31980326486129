import React from "react"

import { Footer } from "./Footer"
import { Nav } from "./Nav"

import "../../style/main.scss"

interface Props {
  children: React.ReactNode
}

export const Layout = ({ children }: Props) => (
  <>
    <Nav />
    <main>{children}</main>
    <Footer />
  </>
)
