import React from "react"

interface Props {
  quote: string
  quoteSource: string
}

export const SectionCaseStudyQuote = ({ quote, quoteSource }: Props) => (
  <section className="case-study-section section-case-study-quote">
    <h3 className="heading-large section-case-study-quote-heading">{quote}</h3>
    <span className="sub-heading sub-heading-large">- {quoteSource}</span>
  </section>
)
