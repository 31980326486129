import React from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"

interface Props {
  href: string
}

export const BlogShare = ({ href }: Props) => {
  return (
    <aside className="blog-share">
      <h2 className="blog-share-heading">
        Found this article useful?
        <br /> Share to help us get the word out
      </h2>
      <div className="blog-share-buttons">
        <FacebookShareButton className="blog-share-button" url={href}>
          <FacebookIcon round={true} bgStyle={{ fill: "#9FA2AF" }} />
        </FacebookShareButton>
        <TwitterShareButton className="blog-share-button" url={href}>
          <TwitterIcon round={true} bgStyle={{ fill: "#9FA2AF" }} />
        </TwitterShareButton>
        <LinkedinShareButton className="blog-share-button" url={href}>
          <LinkedinIcon round={true} bgStyle={{ fill: "#9FA2AF" }} />
        </LinkedinShareButton>
      </div>
    </aside>
  )
}
