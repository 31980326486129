import React from "react"

import { Button, ButtonList } from "../.."

interface Props {
  message: string
  resetForm: () => void
  variant?: "form-enterprise-contact"
  buttonText?: string
}

export const FormSubmitted = ({
  message,
  resetForm,
  variant,
  buttonText,
}: Props) => (
  <div
    className={`form-submitted${variant ? ` form-submitted-${variant}` : ""}`}
  >
    <span className="icon-tick-circle-fill form-submitted-icon" />
    <h3 className="form-submitted-message">{message}</h3>
    <ButtonList variant="centered">
      <Button style="green" onClick={resetForm}>
        {buttonText ? buttonText : "Submit another response"}
      </Button>
    </ButtonList>
  </div>
)
