import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import { UseCaseLinks, LegalPageLinks } from "../../../types"
import { useCaseSlug, legalPageSlug } from "../../../utils"
import { FooterLink } from "./FooterLink"
import { FormJoinMailingList } from "../.."

import GTTLogo from "../../../images/gotthetest-logo-dark.inline.svg"

export interface SocialLinks {
  twitter: string
  linkedIn: string
  facebook: string
  instagram: string
}

export const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query footerContent {
        allContentfulPageUseCase(sort: { order: DESC, fields: name }) {
          edges {
            node {
              contentful_id
              slug
              name
            }
          }
        }
        allContentfulPageLegal(sort: { order: ASC, fields: heading }) {
          edges {
            node {
              slug
              contentful_id
              heading
            }
          }
        }
        site {
          siteMetadata {
            social {
              twitter
              linkedIn
              facebook
              instagram
            }
          }
        }
      }
    `
  )
  const socialLinks: SocialLinks = data?.site?.siteMetadata?.social
  const useCases: UseCaseLinks = data?.allContentfulPageUseCase?.edges
  const legalPages: LegalPageLinks = data?.allContentfulPageLegal?.edges

  return (
    <footer className="wrapper-width footer">
      <section className="footer-primary">
        <div className="footer-primary-social">
          <Link className="footer-primary-social-logo" to="/">
            <GTTLogo />
          </Link>
          <ul className="footer-primary-social-links">
            <li>
              <a
                className="footer-primary-social-link"
                target="_blank"
                rel="noopener"
                href={socialLinks.facebook}
              >
                <span className="icon-facebook" />
              </a>
            </li>
            <li>
              <a
                className="footer-primary-social-link"
                target="_blank"
                rel="noopener"
                href={socialLinks.instagram}
              >
                <span className="icon-instagram" />
              </a>
            </li>
            <li>
              <a
                className="footer-primary-social-link"
                target="_blank"
                rel="noopener"
                href={socialLinks.linkedIn}
              >
                <span className="icon-linkedin" />
              </a>
            </li>
            <li>
              <a
                className="footer-primary-social-link"
                target="_blank"
                rel="noopener"
                href={socialLinks.twitter}
              >
                <span className="icon-twitter" />
              </a>
            </li>
          </ul>
        </div>
        <ul>
          <li>
            <span className="footer-primary-heading">Use cases</span>
          </li>
          {useCases?.map((useCase) => (
            <FooterLink
              key={useCase.node.contentful_id}
              to={useCaseSlug(useCase.node.slug)}
              name={useCase.node.name}
              variant="footer-primary"
            />
          ))}
        </ul>
        <ul>
          <li>
            <span className="footer-primary-heading">GotTheTest</span>
          </li>
          <FooterLink to="/pricing/" name="Pricing" variant="footer-primary" />
          <FooterLink to="/blog/" name="Blog" variant="footer-primary" />
          <FooterLink
            to="/case-studies/"
            name="Case studies"
            variant="footer-primary"
          />
        </ul>
        <div>
          <FormJoinMailingList variant="footer" />
        </div>
      </section>
      <section className="footer-secondary">
        <p className="text-thin-line-height footer-secondary-disclaimer">
          &copy; {new Date().getFullYear()} GotTheTest. All Rights Reserved.
        </p>
        <ul className="footer-secondary-links">
          {legalPages.map((legalPage) => (
            <FooterLink
              variant="footer-secondary"
              key={legalPage.node.contentful_id}
              to={legalPageSlug(legalPage.node.slug)}
              name={legalPage.node.heading}
            />
          ))}
        </ul>
      </section>
    </footer>
  )
}
