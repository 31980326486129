import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { ButtonWithIcon, SectionTextWithImage } from "../.."
import { handleSectionBackground } from "../../../utils"

export const FormEnterpriseContact = () => {
  const data = useStaticQuery(
    graphql`
      query EnterpriseForm {
        contentfulPageSectionTextWithImage(
          contentful_id: { eq: "nqehdESl7f5105D1WqZsJ" }
        ) {
          contentful_id
          showImageFirst
          media {
            ... on ContentfulPageSectionTextWithImageImageVideoUploa {
              media {
                title
                gatsbyImageData
                file {
                  url
                }
              }
            }
            ... on ContentfulPageSectionTextWithImageYouTubeEmbed {
              youTubeEmbedCode {
                youTubeEmbedCode
              }
            }
          }
          heading
          imageBlobBackground
          showSignUpButton
          scheduleDemoButton
          backgroundColour
          scheduleDemoButton
          content {
            raw
          }
        }
      }
    `
  )
  const section = data.contentfulPageSectionTextWithImage

  return (
    <SectionTextWithImage
      contentful_id={section.contentful_id}
      backgroundColour={handleSectionBackground(
        section.backgroundColour,
        "grey-darkest"
      )}
      heading={section.heading}
      content={section.content}
      image={section?.media?.media?.gatsbyImageData}
      imageAlt={section?.media?.media?.title}
      showImageFirst={section.showImageFirst}
      imageBlobBackground={section.imageBlobBackground}
      showSignUpButton={section.showSignUpButton}
      scheduleDemoButton={section.scheduleDemoButton}
      file={section?.media?.media?.file?.url}
      youTubeVideo={section?.media?.youTubeEmbedCode?.youTubeEmbedCode}
    >
      <div className="mt-6">
        <ButtonWithIcon
          href="https://hipaa.jotform.com/220262873747057"
          icon="external-link"
        >
          More about GotTheTest Enterprise
        </ButtonWithIcon>
      </div>
    </SectionTextWithImage>
  )
}
