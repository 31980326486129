import React from "react"
import SVG from "react-inlinesvg"
import { Link } from "gatsby"

import { useCaseSlug } from "../../../../../utils"

interface Props {
  name: string
  slug: string
  summary: string
  icon: string
  variant: "mobile-menu" | "main-menu"
  isOpen: boolean
}

export const UseCase = ({
  name,
  slug,
  summary,
  icon,
  variant,
  isOpen,
}: Props) => (
  <li className="use-case-parent">
    <Link
      className={`use-case use-case-${variant}`}
      to={useCaseSlug(slug)}
      tabIndex={isOpen ? 0 : -1}
    >
      <div className="use-case-illustration">
        <div className="use-case-illustration-svg">
          <SVG src={icon} />
        </div>
      </div>
      <div className="use-case-text">
        <span className="use-case-text-name">{name}</span>
        <span className="use-case-text-summary">{summary}</span>
      </div>
      <span className="icon-chevron-right use-case-icon" />
    </Link>
  </li>
)
