export const blogArticleSlug = (slug: string) => {
  return `/blog/${slug}/`
}

export const blogTopicSlug = (slug: string) => {
  return `/blog/?topic=${slug}`
}

export const useCaseSlug = (slug: string) => {
  return `/use-cases/${slug}/`
}

export const caseStudySlug = (slug: string) => {
  return `/case-studies/${slug}/`
}

export const legalPageSlug = (slug: string) => {
  return `/legal-pages/${slug}/`
}
