import React from "react"
import { Link } from "gatsby"

import { UseCaseLinks } from "../../../../types"

import { UseCase } from "./UseCase"

interface Props {
  isOpen: boolean
  useCases: UseCaseLinks
  variant: "mobile-menu" | "main-menu"
}

export const UseCasesDropdown = ({ isOpen, variant, useCases }: Props) => (
  <div
    className={`use-cases use-cases-${variant}${
      isOpen ? " use-cases-active" : ""
    }`}
  >
    <ul className="use-cases-list">
      {useCases?.map((useCase) => (
        <UseCase
          key={useCase.node.contentful_id}
          slug={useCase.node.slug}
          summary={useCase.node.summary}
          name={useCase.node.name}
          variant={variant}
          icon={useCase.node.icon.file.url}
          isOpen={isOpen}
        />
      ))}
    </ul>
  </div>
)
