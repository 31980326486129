import React from "react"
import { useField, FieldMetaProps } from "formik"

import { Label } from ".."

interface Props {
  label: string
  name: string
  placeholder?: string
  type?: string
  variant?: "form-join-mailing-list-blog" | "form-join-mailing-list-footer"
}

export const Input = ({
  label,
  name,
  placeholder,
  variant,
  type = "text",
}: Props) => {
  const [field, meta] = useField(name)

  return (
    <div
      className={`${meta.touched && meta.error ? "form-error" : ""}${
        variant ? ` input-${variant}` : ""
      }`}
    >
      {label && (
        <Label field={name} variant={variant}>
          {label}
        </Label>
      )}
      <input
        className={`input${meta.touched && meta.error ? " input-error" : ""}`}
        type={type}
        id={name}
        placeholder={placeholder}
        autoComplete={name}
        {...field}
      />
      <span className="input-error-text">{meta.touched ? meta.error : ""}</span>
    </div>
  )
}
