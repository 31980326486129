import React from "react"
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"
import dayjs from "dayjs"

import { RichText, BlogShare } from "../.."

interface Props {
  heading: string
  author: {
    name: string
    contentful_id: string
  }
  createdAt: string
  article: {
    raw: string
  }
  featuredImage: {
    title: string
    gatsbyImageData: IGatsbyImageData
  }
  topic: {
    name: string
    contentful_id: string
  }
  href: string
}

export const BlogArticlePageContent = ({
  heading,
  author,
  createdAt,
  article,
  featuredImage,
  topic,
  href,
}: Props) => {
  return (
    <section className="wrapper-width blog-article-page">
      <div className="blog-article-page-image">
        <GatsbyImage
          className="blog-article-page-image-image"
          image={featuredImage.gatsbyImageData}
          alt={featuredImage.title}
        />
      </div>
      <span className="sub-heading sub-heading-large blog-article-page-sub-heading">
        {topic.name}
      </span>
      <h1 className="heading-large blog-article-page-heading">{heading}</h1>
      <p>
        {dayjs(createdAt).format("MM/DD/YYYY")} &bull; {author.name}
      </p>
      <RichText content={article} variant="blog-article" />
      <BlogShare href={href} />
    </section>
  )
}
