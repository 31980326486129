import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const options: ContentfulRichText = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      return (
        <GatsbyImage
          image={node.data.target.gatsbyImageData}
          alt={node.data.alt}
        />
      )
    },
    [BLOCKS.HEADING_2]: (node: any) => (
      <h2 className="heading-extra-small rich-text-heading">
        {node.content[0].value}
      </h2>
    ),
    [BLOCKS.HEADING_3]: (node: any) => (
      <h3 className="heading-extra-small rich-text-heading">
        {node.content[0].value}
      </h3>
    ),
  },
}

interface ContentfulRichText {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]?: {}
    [BLOCKS.UL_LIST]?: {}
    [BLOCKS.HEADING_1]?: {}
    [BLOCKS.HEADING_2]?: {}
    [BLOCKS.HEADING_3]?: {}
    [BLOCKS.HEADING_4]?: {}
    [BLOCKS.HEADING_5]?: {}
    [BLOCKS.HEADING_6]?: {}
  }
}

interface Props {
  content: any
  variant?: "header-text-only" | "blog-article"
}

export const RichText = ({ content, variant }: Props) => (
  <div className={`rich-text${variant ? ` rich-text-${variant}` : ""}`}>
    {content && renderRichText(content, options)}
  </div>
)
