import React from "react"
import { Link } from "gatsby"

interface Props {
  to: string
  name: string
  variant: "footer-secondary" | "footer-primary"
}

export const FooterLink = ({ to, name, variant }: Props) => (
  <li className={`footer-link footer-link-${variant}`}>
    <Link className="footer-link-item" to={to}>
      <span className="text-thin-line-height footer-link-item-text">
        {name}
      </span>
      <span className="icon-chevron-right footer-link-item-icon" />
    </Link>
  </li>
)
