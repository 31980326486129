import React from "react"

import { BlogTopicLinks } from "../../../../types"
import { NavLink } from "../NavLink"
import { blogTopicSlug } from "../../../../utils"

interface Props {
  variant: "main-menu" | "mobile-menu"
  isOpen: boolean
  blogTopics: BlogTopicLinks
}

export const BlogTopicsDropdown = ({ isOpen, variant, blogTopics }: Props) => (
  <div
    className={`blog-topics blog-topics-${variant}${
      isOpen ? " blog-topics-active" : ""
    }`}
  >
    <ul className="blog-topcs-list">
      <NavLink variant="blog-topics" to="/blog/" tabIndex={isOpen ? 0 : -1}>
        All articles
      </NavLink>
      {blogTopics?.map((blogTopic) => (
        <NavLink
          variant="blog-topics"
          to={blogTopicSlug(blogTopic.node.slug)}
          key={blogTopic.node.contentful_id}
          tabIndex={isOpen ? 0 : -1}
        >
          {blogTopic.node.name}
        </NavLink>
      ))}
    </ul>
  </div>
)
