import React from "react"

import { Button, BulletsIcon } from "../.."

interface Plan {
  plan: any
  allPermissions: any
}

interface PlanBenefit {
  text: string
  icon: string
}

interface PlanBenefits extends Array<PlanBenefit> {}

export const Plan = ({ plan, allPermissions }: Plan) => {
  const premiumPlanId = "4C5KYC2gZfCpCNpVYPIpZY"
  const familyPlanId = "22XgSIw5qIzhg7pHROGzoE"

  const formatPlanBenefits = ({ plan, allPermissions }: Plan) => {
    let formattedPlanBenefits: PlanBenefits = []
    // NEED TO DO: correct types for permission & benefit

    allPermissions.forEach((permission: any) => {
      if (
        plan?.permissions.some(
          (benefit: any) => benefit?.contentful_id === permission?.contentful_id
        )
      ) {
        formattedPlanBenefits.push({
          text: permission?.name,
          icon: "tick-circle-fill",
        })
      } else {
        formattedPlanBenefits.push({
          text: permission?.name,
          icon: "cross-circle-fill",
        })
      }
    })
    return formattedPlanBenefits
  }

  return (
    <div
      className={`plan${plan?.mostPopular ? " plan-most-popular" : ""}`}
      id={`plan-${plan.contentful_id}`}
    >
      {plan?.mostPopular && (
        <div className="plan-most-popular-details">
          <span>Most popular</span>
        </div>
      )}
      <section
        className={`plan-details ${
          plan?.mostPopular ? " plan-details-most-popular" : ""
        }`}
      >
        <div className="plan-details-header">
          <h2 className="plan-details-header-title">{plan?.name}</h2>
          <p className="plan-details-header-description">{plan?.description}</p>
          <div className="plan-details-header-price">
            <span className="plan-details-header-price-value">
              ${plan?.cost}
            </span>
            <span className="plan-details-header-price-period">/mo</span>
          </div>
          <Button
            variant="full-width"
            url={
              plan.contentful_id === familyPlanId
                ? `${process.env.GATSBY_PATIENT_PORTAL}/sign-up?plan=2`
                : plan.contentful_id === premiumPlanId
                ? `${process.env.GATSBY_PATIENT_PORTAL}/sign-up?plan=1`
                : `${process.env.GATSBY_PATIENT_PORTAL}/sign-up`
            }
          >
            Choose this plan
          </Button>
        </div>
        <div className="plan-details-body">
          <BulletsIcon
            gap="large"
            bullets={formatPlanBenefits({ plan, allPermissions })}
          />
        </div>
      </section>
    </div>
  )
}
