import React from "react"

interface Props {
  videoSrcURL: string
  videoTitle: string
  variant?: "section-text-with-image"
}

export const Video = ({ videoSrcURL, videoTitle, variant }: Props) => (
  <video className={variant ? `video-${variant}` : "video"} controls>
    <source src={videoSrcURL} type="video/mp4" />
    Your browser does not support the video tag.
  </video>
)
