import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import dayjs from "dayjs"

import { BlogArticleType } from "../../../types"
import { blogArticleSlug } from "../../../utils"

export const BlogArticle = ({
  contentful_id,
  slug,
  topic,
  author,
  featuredImage,
  createdAt,
  heading,
}: BlogArticleType) => (
  <Link to={blogArticleSlug(slug)} className="blog-article">
    <div className="blog-article-image">
      <GatsbyImage
        className="blog-article-image-image"
        image={featuredImage.gatsbyImageData}
        alt={featuredImage.title}
      />
      <div className="blog-article-image-overlay" />
    </div>
    <div className="blog-article-content">
      <span className="sub-heading">{topic.name}</span>
      <h2 className="heading-small blog-article-content-heading">{heading}</h2>
      <span className="text-thin-line-height blog-article-content-author">
        {dayjs(createdAt).format("MM/DD/YYYY")} &bull; {author.name}
      </span>
    </div>
  </Link>
)
