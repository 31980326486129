import React from "react"
import { navigate } from "gatsby"

import {
  BlogArticleParentType,
  BlogArticleTopicType,
  GatsbyLocationObject,
} from "../../../types"
import {
  BlogArticle,
  Button,
  ButtonList,
  ButtonWithIcon,
  IconButton,
  Loader,
} from "../.."
import { blogTopicSlug } from "../../../utils"

interface Props {
  articles: Array<BlogArticleParentType>
  topics: Array<{ node: BlogArticleTopicType }>
  location: GatsbyLocationObject
}

export const BlogArticles = ({ articles, topics, location }: Props) => {
  const [loading, setLoading] = React.useState(false)
  const [mobileFiltersVisible, setMobileFiltersVisible] = React.useState(false)
  const [blogArticles, setBlogArticles] = React.useState<
    Array<BlogArticleParentType> | []
  >([])

  const defaultFilters = {
    all: [
      {
        contentful_id: "",
        name: "",
        slug: "",
      },
    ],
    selected: {
      contentful_id: "",
      name: "",
      slug: "",
    },
    visible: false,
  }

  const [filters, setFilters] = React.useState(defaultFilters)

  const setUpFilters = () => {
    let formattedTopics: Array<BlogArticleTopicType> = []

    if (topics && topics.length > 0) {
      topics.map(({ node }) => {
        formattedTopics.push(node)
      })

      setFilters((filters) => ({
        ...filters,
        all: formattedTopics,
      }))
    }
  }

  const handleFilterClick = (filter: BlogArticleTopicType) => {
    navigate(blogTopicSlug(filter.slug))
    setMobileFiltersVisible(false)
    setFilters((filters) => ({
      ...filters,
      selected: filter,
      visible: false,
    }))
  }

  const resetFilters = () => {
    navigate("/blog/")
    setFilters((filters) => ({
      ...filters,
      selected: {
        contentful_id: "",
        name: "",
        slug: "",
      },
      visible: false,
    }))
  }

  const filterArticles = () => {
    const filterTopicFromUrl = location.search.split("?topic=")[1]
    setLoading(true)

    if (filters.selected.contentful_id !== "") {
      setBlogArticles(
        articles.filter(
          (article) => article.node.topic.slug === filterTopicFromUrl
        )
      )
    } else {
      if (filterTopicFromUrl && filters.all.length > 0) {
        // User has clicked on filter via nav dropdown, filter articles based on slug
        setBlogArticles(
          articles.filter(
            (article) => article.node.topic.slug === filterTopicFromUrl
          )
        )
        // Set filters to select the topic retrieved from URL
        setFilters((filters) => ({
          ...filters,
          selected: filters.all.find(
            (filter) => filter.slug === filterTopicFromUrl
          ) || {
            contentful_id: "Not found",
            name: "",
            slug: "",
          },
        }))
      } else {
        setBlogArticles(articles)
      }

      setMobileFiltersVisible(false)
    }
    setTimeout(() => setLoading(false), 300)
  }

  React.useEffect(() => {
    filterArticles()
    setUpFilters()
  }, [])

  React.useEffect(() => {
    filterArticles()
  }, [location])

  return (
    <section className="wrapper-width wrapper-bottom">
      <div
        className={`blog-filters${
          mobileFiltersVisible ? " blog-filters-active" : ""
        }`}
      >
        <header className="blog-filters-mobile-header">
          <h2 className="heading-medium">Filter articles</h2>
          <IconButton
            icon="cross"
            onClick={() => setMobileFiltersVisible(false)}
          />
        </header>
        <ButtonList>
          <Button
            variant="tab"
            style={
              filters.selected.contentful_id === "" ? "primary" : "secondary"
            }
            onClick={() => resetFilters()}
          >
            All articles
          </Button>
          {filters.all &&
            filters.all.map((filter: BlogArticleTopicType) => (
              <Button
                key={filter.contentful_id}
                variant="tab"
                style={
                  filters.selected.contentful_id === filter.contentful_id
                    ? "primary"
                    : "secondary"
                }
                onClick={() => handleFilterClick(filter)}
              >
                {filter.name}
              </Button>
            ))}
        </ButtonList>
      </div>
      <div className="blog-articles-mobile-header">
        <h2 className="heading-medium blog-articles-mobile-header-heading">
          {filters.selected.name !== ""
            ? filters.selected.name
            : "All articles"}
        </h2>
        <ButtonWithIcon
          style="secondary"
          icon="filter"
          onClick={() => setMobileFiltersVisible(true)}
        >
          Filter
        </ButtonWithIcon>
      </div>
      {loading ? (
        <div className="blog-articles-loader">
          <Loader colour="orange" />
        </div>
      ) : blogArticles.length > 0 ? (
        <div className="blog-articles">
          {blogArticles.map(({ node }) => (
            <BlogArticle
              key={node.contentful_id}
              contentful_id={node.contentful_id}
              slug={node.slug}
              topic={node.topic}
              author={node.author}
              featuredImage={node.featuredImage}
              createdAt={node.createdAt}
              heading={node.heading}
            />
          ))}
        </div>
      ) : (
        <div className="blog-articles-empty">
          <h2 className="heading-extra-small blog-articles-empty-heading">
            Sorry, there are currently no blog articles in this topic
          </h2>
          <Button style="secondary" onClick={() => resetFilters()}>
            View all articles
          </Button>
        </div>
      )}

      {/* Pagination */}
    </section>
  )
}
