import React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import { UseCaseSymbol } from "../.."

import Blob from "../../../images/blob.inline.svg"

interface Props {
  heading: string
  children: React.ReactNode
  image: IGatsbyImageData
  imageAlt: string
  icon?: string
}

export const HeaderImage = ({
  children,
  heading,
  image,
  icon,
  imageAlt,
}: Props) => (
  <header className="wrapper-width header-image">
    <div className="header-image-content">
      {icon && <UseCaseSymbol icon={icon} />}
      <h1 className="heading-extra-large heading-image-content-heading">
        {heading}
      </h1>
      {children}
    </div>
    <div className="header-image-graphic">
      <div className="header-image-graphic-background">
        <Blob />
      </div>
      <GatsbyImage
        className="header-image-graphic-image"
        image={image}
        alt={imageAlt}
      />
    </div>
  </header>
)
