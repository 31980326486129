import React from "react"
import PropTypes from "prop-types"

interface Props {
  field?: string
  children: React.ReactNode
  variant?: "form-join-mailing-list-footer" | "form-join-mailing-list-blog"
}

export const Label = ({ field, variant, children }: Props) => (
  <label
    htmlFor={field}
    className={`label${variant ? ` label-${variant}` : ""}`}
  >
    <span className="label-text">{children}</span>
  </label>
)
