import React from "react"
import { Plan } from ".."

interface Props {
  plans: any
  allPermissions: any
}

export const PlansSection = ({ plans, allPermissions }: Props) => {
  return (
    <div className="wrapper-width wrapper-bottom plans">
      {plans.map((plan: any) => (
        <Plan
          key={plan.contentful_id}
          plan={plan}
          allPermissions={allPermissions}
        />
      ))}
    </div>
  )
}
