import React from "react"

interface Props {
  icon: string
  children: React.ReactNode
}

export const BulletsIconBullet = ({ icon, children }: Props) => (
  <div className="bullets-icon-bullet">
    <span className={`icon-${icon} bullets-icon-bullet-icon`} />
    <span className="bullets-icon-bullet-text">{children}</span>
  </div>
)
