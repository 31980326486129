export const handleSectionBackground = (
  contentfulBackgroundColour: string,
  automatic: any
) => {
  if (contentfulBackgroundColour === "Light Grey") {
    return "grey-lightest"
  } else if (contentfulBackgroundColour === "White") {
    return "white"
  } else if (contentfulBackgroundColour === "Dark Grey") {
    return "grey-darkest"
  } else {
    return automatic
  }
}
