import React from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"

import { Button, Input, FormSubmitted, Tag } from "../.."

interface Props {
  variant: "footer" | "blog"
}

export const FormJoinMailingList = ({ variant }: Props) => {
  const [submitted, setSubmitted] = React.useState(false)
  const [error, setError] = React.useState("")

  const onSubmit = async (
    values: { email: string },
    { setSubmitting }: { setSubmitting: any }
  ) => {
    try {
      await fetch(
        `${process.env.GATSBY_GOTTHETEST_API}/marketing-site/klaviyo/list`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            profiles: [{ email: values.email }],
          }),
        }
      )
      setSubmitted(true)
    } catch (error) {
      setError(
        "Sorry, something went wrong. Please try submitting the form again"
      )
    }
    setSubmitting(false)
  }

  if (submitted) {
    return (
      <FormSubmitted
        message="Thank you for joining our mailing list"
        resetForm={() => setSubmitted(false)}
        buttonText="Add another email"
      />
    )
  } else {
    return (
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Please enter a valid email address")
            .required("Please enter an email address"),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form
            className={`form-join-mailing-list form-join-mailing-list-${variant}`}
          >
            <Input
              name="email"
              label="News straight to your inbox"
              placeholder="Your email"
              variant={`form-join-mailing-list-${variant}`}
            />
            <div>
              {variant === "blog" ? (
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  variant="form-join-mailing-list-blog"
                >
                  Stay updated
                </Button>
              ) : (
                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Stay updated
                </Button>
              )}
              {error && (
                <Tag type="error" variant="margin-top">
                  {error}
                </Tag>
              )}
            </div>
          </Form>
        )}
      </Formik>
    )
  }
}
