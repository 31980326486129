import React from "react"

interface Props {
  icon:
    | "arrow-bottom"
    | "arrow-left"
    | "arrow-right"
    | "arrow-top"
    | "cross"
    | "eye"
    | "eye-off"
    | "menu"
    | "more"
  type?: "button" | "submit"
  disabled?: boolean
  onClick: () => void
}

export const IconButton = ({
  icon,
  type = "button",
  disabled = false,
  onClick,
}: Props) => (
  <button
    className="icon-button"
    type={type}
    disabled={disabled}
    onClick={onClick}
  >
    <span className={`icon-button-icon icon-${icon}`}></span>
  </button>
)
